<template>
  <div style="height: 65px">
    <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        background-color="#000000"
        text-color="#ffffff"
        active-text-color="#ffd04b"
        router
        :collapse-transition="false">
      <el-menu-item index="/agent/agent_main_statistic_simple"
                    :route="{path:'/agent/agent_main_statistic_simple',query:{'t':new Date().getTime()}}">홈
      </el-menu-item>
      <el-menu-item index="/agent/agent_sub_list"
                    :route="{path:'/agent/agent_sub_list',query:{'t':new Date().getTime()}}">
        총판목록
      </el-menu-item>
      <el-menu-item index="/agent/agent_user_list"
                    :route="{path:'/agent/agent_user_list',query:{'t':new Date().getTime()}}">
        회원목록
      </el-menu-item>
      <el-menu-item index="/agent/agent_recharge_list"
                    :route="{path:'/agent/agent_recharge_list',query:{'t':new Date().getTime()}}">
        회원입금
      </el-menu-item>
      <el-menu-item index="/agent/agent_exchange_list"
                    :route="{path:'/agent/agent_exchange_list',query:{'t':new Date().getTime()}}">
        회원출금
      </el-menu-item>
      <el-menu-item index="/agent/agent_subagent_exchange_list"
                    :route="{path:'/agent/agent_subagent_exchange_list',query:{'t':new Date().getTime()}}">
        에이전트출금
      </el-menu-item>
      <el-menu-item index="/agent/agent_user_cashpoint_history"
                    :route="{path:'/agent/agent_user_cashpoint_history',query:{'t':new Date().getTime()}}">
        회원머니로그
      </el-menu-item>
      <el-menu-item index="/agent/agent_logcash_list"
                    :route="{path:'/agent/agent_logcash_list',query:{'t':new Date().getTime()}}">
        에이전트머니로그
      </el-menu-item>
      <el-submenu index="300" show-timeout="50" hide-timeout="260">
        <template slot="title">
          베팅내역
        </template>
        <el-menu-item index="/agent/agent_sportsbet"
                      :route="{path:'/agent/agent_sportsbet',query:{'t':new Date().getTime()}}">스포츠 베팅내역
        </el-menu-item>
        <el-menu-item index="/agent/agent_leisurebet"
                      :route="{path:'/agent/agent_leisurebet',query:{'t':new Date().getTime()}}">미니게임 베팅내역
        </el-menu-item>
        <el-menu-item index="/agent/agent_casino_honor_bet"
                      :route="{path:'/agent/agent_casino_honor_bet',query:{'t':new Date().getTime()}}">Casino
          베팅내역
        </el-menu-item>
        <!--                <el-menu-item index="/agent/agent_tgame365_bet"-->
        <!--                              :route="{path:'/agent/agent_tgame365_bet',query:{'t':new Date().getTime()}}">토큰게임 베팅내역-->
        <!--                </el-menu-item>-->
      </el-submenu>

      <el-menu-item index="/agent/agent_move_cash" v-if="$store.state.agent.agentInfo.moneymoveable == 1"
                    :route="{path:'/agent/agent_move_cash',query:{'t':new Date().getTime()}}">
        머니이동
      </el-menu-item>
      <el-menu-item index="/agent/agent_cash_recharge"
                    :route="{path:'/agent/agent_cash_recharge',query:{'t':new Date().getTime()}}">
        충전신청
      </el-menu-item>
      <el-menu-item index="/agent/agent_cash_exchange" v-if="$store.state.agent.agentInfo.exchangeable == 1"
                    :route="{path:'/agent/agent_cash_exchange',query:{'t':new Date().getTime()}}">
        환전신청
      </el-menu-item>

      <el-menu-item index="/agent/agent_customercenter"
                    :route="{path:'/agent/agent_customercenter',query:{'t':new Date().getTime()}}">
        고객센터
      </el-menu-item>
      <el-menu-item index="/agent/agent_message"
                    :route="{path:'/agent/agent_message',query:{'t':new Date().getTime()}}">
        쪽지
      </el-menu-item>

      <el-menu-item index="/agent/logout" @click="logout" style="color: red">
        로그아웃
      </el-menu-item>
    </el-menu>
  </div>


</template>

<script>

import {agentMixin} from "../../common/agent/agentMixin";
import {agentLogout, getAgentById} from "../../network/agent/commonRequest";

export default {
  name: "AgentTopbarComp",
  components: {},
  mixins: [agentMixin],
  data() {
    return {
      activeIndex: '1',
    }
  },
  methods: {
    logout() {
      agentLogout();
      this.$router.push({path: '/partner'})
    }

  },
  computed: {},
  created() {
    getAgentById(-1).then(res => {
      this.$store.state.agent.agentInfo = res.data.data;
    })
  },
  mounted() {

  },
  watch: {},
}
</script>

<style scoped>
.header {
  background-color: #143771;
}

.el-menu {
  border: 0 solid transparent !important;
  font-weight: bold;
}

.tick {
  position: absolute;
  border-radius: 5px;
  font-size: 10px;
  top: 5px;
  right: 2px
}

.vp {
  position: absolute;
  top: -1000px;
}

.countAni {
  animation: 1s ease-in-out 0s infinite normal none running twinkling;
}


@keyframes twinkling {

  0% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}
</style>